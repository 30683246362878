import * as React from "react";
import { Header } from "@fluentui/react";
import { addWeeks, differenceInCalendarWeeks, endOfWeek, format } from "date-fns";

import { Plan } from "../../server/plans/Plan";
import { Client, weekOptions } from "../../common";
import { ProgressBar } from "../ProgressBar";

import { DashboardCard, dashboardCardHeaderDescriptionStyle, dashboardCardHeaderStyle } from "./DashboardCard";

interface PlanCardProps {
    plan: Client<Plan>;
}

const PlanCard: React.FunctionComponent<PlanCardProps> = ({ plan }) => {

    const start = new Date(plan.start);
    const end = new Date(plan.end);
    const range = format(start, "PP") + " - " + format(end, "PP");

    const endOfLastWeek = endOfWeek(addWeeks(new Date(), -1), weekOptions);

    const weeksLeft = differenceInCalendarWeeks(end, endOfLastWeek, weekOptions);
    const totalWeeks = differenceInCalendarWeeks(addWeeks(end, 1), start, weekOptions);

    const progress = ((totalWeeks - weeksLeft) / totalWeeks) * 100;

    return (
        <DashboardCard
            header={plan.name}
            subHeader={range}
            footer={<ProgressBar progress={progress} />}
            link="/plan"
        >
            <Header as="h2" styles={dashboardCardHeaderStyle} align="center">
                {weeksLeft}
                <Header.Description as="span" styles={dashboardCardHeaderDescriptionStyle}>
                    Weeks to go
                </Header.Description>
            </Header>
        </DashboardCard>
    );
};

export { PlanCard };
