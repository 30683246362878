import { useState } from "react";

export const useDialog = () => {

    const [isOpen, setOpen] = useState(false);

    const open = (event?: React.SyntheticEvent) => {
        event?.stopPropagation();
        event?.preventDefault();
        setOpen(true);
    };

    const close = (event?: React.SyntheticEvent) => {
        event?.stopPropagation();
        event?.preventDefault();
        setOpen(false);
    };

    const toggle = (event?: React.SyntheticEvent) => {
        event?.stopPropagation();
        event?.preventDefault();
        setOpen(!isOpen);
    };

    return {
        isOpen,
        open,
        close,
        toggle
    };
};
