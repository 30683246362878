import * as React from "react";
import { Button, ComponentSlotStyle } from "@fluentui/react";
import { endOfDay, isBefore, isEqual } from "date-fns";

import { Event } from "../../server/events/Event";
import { Client } from "../../common";
import { useEditEvent } from "../events/useEditEvent";

import { DashboardCard } from "./DashboardCard";

const skipButtonStyles = (skipped?: boolean): ComponentSlotStyle => styles => ({
    color: skipped
        ? styles?.theme.siteVariables.colors.red[500] + " !important"
        : styles?.theme.siteVariables.colors.grey[400]
});

const completeButtonStyles = (complete?: boolean): ComponentSlotStyle => styles => ({
    color: complete
        ? styles?.theme.siteVariables.colors.green[500] + " !important"
        : styles?.theme.siteVariables.colors.brand[600]
});

interface WorkoutCardProps {
    workout?: Client<Event>;
    subHeader: string;
    refetch: () => any;
}

const isTodayOrPast = (value: string | Date) => {
    const today = endOfDay(new Date());
    const date = new Date(value);

    return isEqual(date, today) || isBefore(date, today);
};

const WorkoutCard: React.FunctionComponent<WorkoutCardProps> = ({ workout, subHeader, refetch }) => {

    const { complete, skip, skipDialog } = useEditEvent(workout, refetch);

    const completeStyles = React.useMemo(() => completeButtonStyles(workout?.complete), [workout?.complete]);
    const skipStyles = React.useMemo(() => skipButtonStyles(workout?.skipped), [workout?.skipped]);

    return workout
        ? (
            <DashboardCard
                header={workout.name}
                subHeader={subHeader}
                link="/calendar"
            >
                {isTodayOrPast(workout.date) && (
                    <>
                        {workout.complete === false && (
                            <Button
                                content={workout.skipped ? "Skipped" : "Skip"}
                                icon="ban"
                                styles={skipStyles}
                                onClick={skip}
                                disabled={workout.skipped}
                                text
                            />
                        )}
                        {workout.skipped === false && (
                            <Button
                                content="Complete"
                                icon="icon-checkmark"
                                styles={completeStyles}
                                onClick={complete}
                                disabled={workout.complete}
                                text
                            />
                        )}
                        {skipDialog}
                    </>
                )}
            </DashboardCard>
        )
        : (
            <>No workouts</>
        );
};

export { WorkoutCard };
