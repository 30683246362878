import { ThemeInput } from "@fluentui/react";
import { createColorScheme } from "@fluentui/react/dist/es/themes/teams/colors";

import { svgContent} from "./svgContent";

// const startingColors = {
//     brand: "#369693", // turquoise
//     lightShades: "#E4DFDA", // off white - use for text background
//     lightAccent: "#5DA6BE", // light blue
//     darkAccent: "#907187", // purple
//     darkShades: "#2E5B74", // dark blue - use for text

//     // semantics:
//     primary: "#389694",
//     info: "#2d5974",
//     success: "#46a864",
//     warning: "#c3972c",
//     danger: "#f44336"
// };

export const primitiveColors = {
    black: "#000",
    white: "#fff",
    gold: "#FFD700"
};

const contextualColors = {
    brand: {
        0: "#FFFFFF", // white
        25: "#eef9f8",
        50: "#dcf3f2",
        100: "#cbeceb",
        150: "#bae6e5",
        200: "#a8e0de",
        250: "#97dad8",
        300: "#86d3d1",
        350: "#75cdca",
        400: "#63c7c4",
        450: "#52c1bd",
        500: "#42b9b5",
        550: "#3ca7a4",
        600: "#369693", // main brand colour
        650: "#2e817e",
        700: "#276b69",
        750: "#1f5654",
        800: "#17403f",
        850: "#0f2b2a",
        900: "#081515",
        1000: "#000000" // black
    },

    secondary: {
        0: "#FFFFFF", // white
        25: "#f6f4f6",
        50: "#eee9ed",
        100: "#e5dee3",
        150: "#ddd3da",
        200: "#d4c8d1",
        250: "#ccbdc8",
        300: "#c3b3be",
        350: "#bba8b5",
        400: "#b29dac",
        450: "#aa92a3",
        500: "#a18799",
        550: "#997c90",
        600: "#907187", // main secondary colour
        650: "#7c6174",
        700: "#675161",
        750: "#52404d",
        800: "#3e303a",
        850: "#292027",
        900: "#151013",
        1000: "#000000" // black
    }
};

const naturalColors = {
    grey: {
        0: "#FFFFFF", // white
        25: "#FCFCFB", // old $app-density-message-initial-hover-focus
        50: "#FAF9F8", // light14
        100: "#F3F2F1", // light10, old $app-density-message-background-replay-hover-focus
        150: "#EDEBE9", // light09, old $app-density-border-gray
        200: "#E1DFDD", // light08
        250: "#C8C6C4", // light06, dark02
        300: "#B3B0AD", // dark03
        350: "#979593", // light04
        400: "#8A8886", // dark04
        450: "#605E5C", // light03, dark06, $app-gray-20-theme-agnostic, old $message-highlight-color-darktheme
        500: "#484644", // light02, dark08
        550: "#3B3A39", // dark09
        600: "#323131", // dark10, in call audio only grid slot 4
        650: "#2D2C2C", // in call audio only grid slot 3
        700: "#292828", // dark14, in call audio only grid slot 2, old $app-density-message-background-initial-hover-focus-darktheme
        750: "#252423", // 900 - different [#252424]
        800: "#201F1F", // app black darktheme, in call title bar, in call audio only pip
        850: "#1B1A1A", // in call background behind presented doc, old $app-density-message-border-darktheme
        900: "#11100F", // dark theme borders
        1000: "#000000", // black
    },

    blue: {
        0: "#FFFFFF", // white
        25: "#ecf3f7",
        50: "#d9e8f0",
        100: "#c5dce8",
        150: "#b2d0e1",
        200: "#9fc4d9",
        250: "#8cb9d1",
        300: "#79adca",
        350: "#66a1c2",
        400: "#5295bb", // light accent
        450: "#4588ae",
        500: "#3d799a",
        550: "#366a87",
        600: "#2E5B74", // dark accent
        650: "#274e63",
        700: "#214153",
        750: "#1a3442",
        800: "#142732",
        850: "#0d1a21",
        900: "#070d11ss",
        1000: "#000000", // black
    },

    green: {
        0: "#FFFFFF", // white
        25: "#f1f8f3",
        50: "#e3f1e8",
        100: "#d5eadc",
        150: "#c7e3d0",
        200: "#b9dcc4",
        250: "#aad5b9",
        300: "#9ccead",
        350: "#8ec7a1",
        400: "#80c095",
        450: "#72b98a",
        500: "#64B27E", // success
        550: "#52a56e",
        600: "#489060",
        650: "#3e7c52",
        700: "#336745",
        750: "#295337",
        800: "#1f3e29",
        850: "#15291b",
        900: "#0a150e",
        1000: "#000000", // black
    },
    red: {
        0: "#FFFFFF", // white
        25: "#feeeed",
        50: "#fdddda",
        100: "#fcccc8",
        150: "#fbbbb6",
        200: "#faaaa4",
        250: "#f99891",
        300: "#f8877f",
        350: "#f7766d",
        400: "#f6655b",
        450: "#f55448",
        500: "#F44336", // danger
        550: "#f22617",
        600: "#dc1a0c",
        650: "#bc160a",
        700: "#9d1309",
        750: "#7e0f07",
        800: "#5e0b05",
        850: "#3f0703",
        900: "#1f0402",
        1000: "#000000", // black
    },

    yellow: {
        0: "#FFFFFF", // white
        25: "#faf6eb",
        50: "#f6edd7",
        100: "#f1e4c3",
        150: "#eddbaf",
        200: "#e8d29a",
        250: "#e4c986",
        300: "#dfc072",
        350: "#dbb65e",
        400: "#d6ad4a",
        450: "#d2a436",
        500: "#c3972c", // warning
        550: "#ad8627",
        600: "#987522",
        650: "#82651d",
        700: "#6c5418",
        750: "#574314",
        800: "#41320f",
        850: "#2b220a",
        900: "#161105",
        1000: "#000000", // black
    }
};

const colors = {
    ...primitiveColors,
    ...contextualColors,
    ...naturalColors
};

const greenScheme = createColorScheme({
    background: colors.green[500],
    backgroundHover: colors.green[600],
    backgroundActive: colors.green[400],
    foreground: colors.white,
    foregroundHover: colors.white,
    foregroundActive: colors.white,

    border: colors.green[200],
    borderHover: colors.green[300],
});

const redScheme = createColorScheme({

    background: colors.red[500],
    backgroundHover: colors.red[600],
    backgroundActive: colors.red[400],

    foreground: colors.white,
    foregroundHover: colors.grey[50],
    foregroundActive: colors.grey[50],

    background2: colors.white,
    backgroundHover2: colors.grey[50],
    backgroundActive2: colors.grey[100],

    foreground2: colors.red[500],
    foregroundHover2: colors.red[600],
    foregroundActive2: colors.red[400],

    border: colors.red[200],
    borderHover: colors.red[300],
    borderActive: colors.red[300]

});

const brandScheme = createColorScheme({
    foreground: colors.brand[600],
    foreground1: colors.brand[600],
    foreground2: colors.brand[700],
    foreground3: colors.brand[200],
    foreground4: colors.white,

    background: colors.brand[600],
    background1: colors.brand[100],
    background2: colors.brand[900],
    background3: colors.brand[1000],
    background4: colors.brand[800],

    border: colors.grey[200],
    border1: colors.brand[200],
    border2: colors.brand[300],

    shadow: colors.black, // opacity 25%
    shadowHover: colors.black,

    foregroundHover: colors.brand[600],
    foregroundHover1: colors.white,
    foregroundHover2: colors.brand[200],

    borderHover: colors.brand[300],

    backgroundHover: colors.brand[800],
    backgroundHover1: colors.brand[50],

    foregroundPressed: colors.brand[800],
    foregroundPressed1: colors.white,
    backgroundPressed: colors.brand[800], // it's 900 on the button - 800 is same as hover
    borderPressed: colors.brand[300],

    foregroundActive: colors.brand[600],
    foregroundActive1: colors.brand[600],
    foregroundActive2: colors.brand[200],

    backgroundActive: colors.brand[600],
    backgroundActive1: colors.brand[600],

    borderActive: colors.grey[200],
    borderActive1: colors.brand[200],
    borderActive2: colors.brand[300],

    foregroundFocus: colors.brand[600],
    foregroundFocus1: colors.brand[600],
    foregroundFocus2: colors.brand[700],
    foregroundFocus3: colors.brand[200],
    foregroundFocus4: colors.white,

    backgroundFocus: colors.brand[600],
    backgroundFocus1: colors.brand[100],
    backgroundFocus2: colors.brand[900],
    backgroundFocus3: colors.brand[1000],

    borderFocus: colors.black,
    borderFocusWithin: colors.white,
    borderFocus1: colors.brand[600], // only input

    foregroundDisabled: colors.grey[250],
    foregroundDisabled1: colors.grey[250],

    backgroundDisabled: colors.grey[150],
    backgroundDisabled1: colors.grey[150],

    borderDisabled: colors.grey[150],
});

const colorScheme = {
    brand: brandScheme,
    green: greenScheme,
    red: redScheme
};

export const theme: ThemeInput = {

    siteVariables: {
        colors,
        colorScheme
    },

    componentVariables: {
        Loader: {
            svgContent
        }
    },

    componentStyles: {

        Button: {
            root: {
                textDecoration: "none"
            }
        },

        Dialog: {
            root: () => ({
                "@media screen and (max-width: 60em)": {
                    width: "90vw"
                }
            }),
            content: () => ({
                marginBottom: "0"
            })
        },

        MenuItem: {
            root: (styles) => ({
                "color": styles?.theme.siteVariables.colors.white,
                ":hover": {
                    color: styles?.theme.siteVariables.colors.white,
                    backgroundColor: styles?.theme.siteVariables.colors.brand[550]
                }
            })
        }
    }
};
