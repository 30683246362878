import * as React from "react";
import { Link } from "react-router-dom";
import { Button, ComponentSlotStyle, Flex, Header, Loader } from "@fluentui/react";
import { differenceInCalendarWeeks } from "date-fns";

import { Container } from "../Container";

import { WorkoutCard } from "./WorkoutCard";
import { CompetitionCard } from "./CompetitionCard";
import { PlanCard } from "./PlanCard";
import { WeekSummaryCard } from "./WeekSummaryCard";
import { useDashboardData } from "./useDashboardData";
import { getDateDescription } from "./getDateDescription";

const sectionHeaderStyle: ComponentSlotStyle = (styles) => ({
    fontWeight: "normal",
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    color: styles?.theme.siteVariables.colors.grey[450],
    borderColor: styles?.theme.siteVariables.colors.grey[200],
    marginBlockEnd: "0.5em"
});

export const Dashboard: React.FunctionComponent = () => {

    const {
        loading,
        today,
        todaysWorkouts,
        lastWorkout,
        nextCompetition,
        currentPlan,
        workoutTotals,
        currentGoal,
        refetchWorkouts
    } = useDashboardData();

    if (loading) {
        return <Loader size="larger" />;
    }

    const weeksTillCompetition = nextCompetition?.date && differenceInCalendarWeeks(new Date(nextCompetition?.date), today);

    let todaysWorkoutsLabel = "Big day!";

    if (todaysWorkouts?.length === 0) {
        todaysWorkoutsLabel = "Take it easy";
    }

    if (todaysWorkouts?.length === 1) {
        todaysWorkoutsLabel = todaysWorkouts[0]?.complete || todaysWorkouts[0]?.skipped
            ? "All done for today."
            : "Are you ready for this?";
    }

    return (
        <Container styles={{ marginTop: "1em" }}>

            <Header
                as="h3"
                styles={sectionHeaderStyle}
                content={todaysWorkoutsLabel}
            />
            {todaysWorkouts?.length === 0 && <>No workouts today</>}
            {todaysWorkouts?.map(workout => (
                <WorkoutCard key={workout._id} workout={workout} subHeader={`${workout.duration} hours`} refetch={refetchWorkouts} />
            ))}

            <Header
                as="h3"
                styles={sectionHeaderStyle}
                content={lastWorkout?.complete ? "Good work!" : "How did this go?"}
            />
            <WorkoutCard workout={lastWorkout} subHeader={getDateDescription(lastWorkout?.date)} refetch={refetchWorkouts} />

            <Header
                as="h3"
                styles={sectionHeaderStyle}
                content="You're on track for this week"
            />
            <WeekSummaryCard goal={currentGoal?.hours ?? 0} planned={workoutTotals?.planned ?? 0} complete={workoutTotals?.complete ?? 0} />

            {nextCompetition && (
                <>
                    <Header
                        as="h3"
                        styles={sectionHeaderStyle}
                        content={weeksTillCompetition === 0 ? "This week" : `${weeksTillCompetition} weeks to go!`}
                    />
                    <CompetitionCard competition={nextCompetition} />
                </>
            )}

            {currentPlan && (
                <>
                    <Header as="h3" styles={sectionHeaderStyle} content="Keep it up" />
                    <PlanCard plan={currentPlan} />
                </>
            )}

            <Flex styles={{ marginTop: "1em" }}>
                <Button primary as={Link} to="/plan" content="Training Plan" icon="bullets" />
                <Flex.Item push>
                    <Button primary as={Link} to="/calendar" content="Calendar" icon="calendar" />
                </Flex.Item>
            </Flex>
        </Container>
    );
};
