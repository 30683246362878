type DateIfDefined<T extends Date | string | undefined> = undefined extends T
    ? undefined
    : Date;

const isUndefined = (value: any): value is undefined => value === undefined;

export const getDate = <T extends Date | string | undefined>(
    value: T
): DateIfDefined<T> => {

    if (isUndefined(value)) {
        return value as unknown as DateIfDefined<T>;
    }

    if (value instanceof Date) {
        return value as unknown as DateIfDefined<T>;
    }

    return new Date(value as string) as unknown as DateIfDefined<T>;
};
