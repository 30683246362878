import * as React from "react";

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {

    public static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    public state = {
        hasError: false
    };

    public componentDidCatch() {
        // todo log error
    }

    public render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}
