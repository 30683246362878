import * as React from "react";
import { ComponentSlotStyle, Flex, FlexItem, Menu, Segment } from "@fluentui/react";
import { Link } from "react-router-dom";

import { config } from "../config";
import { useCurrentUser } from "../user/useCurrentUser";

const menuBarStyles: ComponentSlotStyle = (styles) => ({
    padding: "0.25rem",
    borderBottom: "solid 0.0714rem rgb(225, 223, 221)",
    background: styles?.theme.siteVariables.colorScheme.brand.background
});

export const MenuBar: React.FunctionComponent = () => {

    const [user, loading] = useCurrentUser();

    return (
        <Segment styles={menuBarStyles}>
            <Flex>
                {user != null && loading === false && (
                    <Menu
                        items={[
                            { icon: "tabs", iconOnly: true, key: "home", as: Link, to: "/" }
                        ]}
                        pills
                    />
                )}
                {user && (
                    <FlexItem push>
                        <Menu
                            pills
                            items={[
                                { icon: "calendar", iconOnly: true, key: "calendar", as: Link, to: "/calendar" }
                            ]}
                        />
                    </FlexItem>
                )}
                {user === undefined && loading === false && (
                    <FlexItem push>
                        <Menu
                            pills
                            items={[
                                { key: "login", content: "Login", as: "a", href: `${config.serverUrl}/login` }
                            ]}
                        />
                    </FlexItem>
                )}
            </Flex>
        </Segment>
    );
};
