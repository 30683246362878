import { createContext } from "react";

export interface ToastOptions {
    type?: "info" | "success" | "warning" | "error";
    timeout?: number;
}

export type ToastFunction = (message: string, options?: ToastOptions) => void;

export interface ToastContextType {
    toast: ToastFunction;
}

export const ToastContext = createContext<ToastContextType>({ toast: () => undefined });
