import * as React from "react";
import { Box, ComponentSlotStyle } from "@fluentui/react";

const progressStyles = (
    progress: number,
    width: number = 100,
    height: number = 6
): ComponentSlotStyle => (styles) => ({
    "height": `${height}px`,
    "backgroundColor": styles?.theme.siteVariables.colors.grey[250],
    "borderRadius": "3px",
    "width": `100%`,
    "zIndex": "2",
    "position": "relative",
    "::after": {
        content: "' '",
        width: `${progress}%`,
        height: `${height}px`,
        display: "block",
        borderRadius: "3px",
        backgroundColor: styles?.theme.siteVariables.colors.green[500],
        position: "absolute",
        zIndex: "3"
    },
    "::before": {
        content: "' '",
        width: `${width}%`,
        height: `${height}px`,
        display: "block",
        borderRadius: "3px",
        backgroundColor: styles?.theme.siteVariables.colors.green[300],
        position: "absolute",
        zIndex: "1"
    }
});

interface ProgressBarProps {
    progress: number | [number, number];
    height?: number;
}

const ProgressBar: React.FunctionComponent<ProgressBarProps> = ({ progress, height }) => {

    const styles =  React.useMemo(
        () => Array.isArray(progress)
            ? progressStyles(progress[0], progress[1], height)
            : progressStyles(progress, 100, height),
        [progress]
    );

    return (
        <Box styles={styles} />
    );
};

export { ProgressBar };
