import * as React from "react";
import { Alert, Box, ComponentSlotStyle } from "@fluentui/react";
import { Transition, TransitionGroup } from "react-transition-group";
import { TransitionStatus } from "react-transition-group/Transition";

import { wait } from "../../common";

import { ToastContext, ToastOptions } from "./ToastContext";
import { addToast, removeToast, toastReducer } from "./toastReducer";

export interface ToastInfo extends ToastOptions {
    message: string;
}

const transitionDuration = 200;

const containerStyles: ComponentSlotStyle = {
    "position": "fixed",
    "right": "1em",
    "top": "4em",
    "width": "40%",
    "@media screen and (max-width: 60em)": {
        left: "1em",
        right: "1em",
        width: "auto"
    }
};

const alertStyles: ComponentSlotStyle = {
    transition: `${transitionDuration}ms ease`,
    boxShadow: "0 2px 4px 0 rgb(35,36,38,0.12), 0 2px 10px 0 rgb(35,36,38,0.15)",
    marginBottom: "0.5em",
    width: "100%"
};

const alertTransitionStyles: { [K in TransitionStatus]?: ComponentSlotStyle } = {
    entering: { transform: "translateX(110%)" },
    entered:  { transform: "translateX(0%)" },
    exiting: { transform: "translateX(110%)", transition: `${transitionDuration * 0.7}ms` }
};

const defaultOptions: Required<ToastOptions> = {
    timeout: 5000,
    type: "info"
};

const getAlertProps = (toastInfo: ToastInfo) => {
    switch (toastInfo.type) {
        case "success":
            return {
                icon: "accept",
                success: true
            };
        case "warning":
            return {
                icon: "exclamation-circle",
                warning: true
            };
        case "error":
            return {
                icon: "exclamation-triangle",
                variables: {
                    urgent: true
                }
            };
        case "info":
        default:
            return {
                icon: "info",
                info: true
            };
    }
};

const getId = () => new Date().valueOf();

const ToastContainer: React.FunctionComponent = ({ children }) => {

    const [toasts, dispatch] = React.useReducer(toastReducer, []);

    const toast = async (message: string, options?: ToastOptions) => {
        const id = getId();
        dispatch(addToast(id, message, { ...defaultOptions, ...options }));
        await wait(options?.timeout ?? defaultOptions.timeout);
        dispatch(removeToast(id));
    };

    const onClose = (id: number) => (event: React.SyntheticEvent) => {
        event.preventDefault();
        dispatch(removeToast(id));
    };

    return (
        <ToastContext.Provider value={{ toast }}>
            {children}
            <Box styles={containerStyles}>
                <TransitionGroup>
                    {toasts.map(t => (
                        <Transition key={t.id} timeout={{ enter: transitionDuration, exit: t.timeout }}>
                            {state =>
                                <Alert
                                    content={t.message}
                                    styles={{
                                        ...alertStyles,
                                        ...alertTransitionStyles[state]
                                    }}
                                    {...getAlertProps(t)}
                                    onDismiss={onClose(t.id)}
                                    dismissible
                                />
                            }
                        </Transition>
                    ))}
                </TransitionGroup>
            </Box>
        </ToastContext.Provider>
    );
};

export { ToastContainer };
