import * as React from "react";
import { Box, ComponentSlotStyle } from "@fluentui/react";

import { ProgressBar } from "../ProgressBar";

import { DashboardCard } from "./DashboardCard";

const weekSummaryStyles: ComponentSlotStyle = (styles) => ({
    paddingLeft: "0.5em",
    marginBottom: "0.2em",
    color: styles?.theme.siteVariables.colors.grey[750]
});

interface WeekSummaryCardProps {
    goal: number;
    planned: number;
    complete: number;
}

const WeekSummaryCard: React.FunctionComponent<WeekSummaryCardProps> = ({ goal, planned, complete }) => {

    const progress: [number, number] = [
        (complete / goal * 100),
        (planned / goal * 100)
    ];

    return (
        <DashboardCard
            header="3rd Feb to 9th Feb"
            headerAs="h3"
            footer={
                <Box>
                    <Box styles={weekSummaryStyles}>{planned} out of {goal} hours planned</Box>
                    <Box styles={weekSummaryStyles}>{complete} hours complete</Box>
                    <ProgressBar progress={progress} />
                </Box>
            }
            link="/calendar"
        />
    );
};

export { WeekSummaryCard };
