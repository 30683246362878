import * as React from "react";
import { ComponentSlotStyle, Icon } from "@fluentui/react";
import { formatISO } from "date-fns";

import { Client, getDate } from "../../common";
import { Event } from "../../server/events/Event";

import { DashboardCard } from "./DashboardCard";
import { getDateDescription } from "./getDateDescription";

const iconStyle: ComponentSlotStyle = style => ({
    color: style?.theme.siteVariables.colors.gold,
    marginRight: "1em"
});

interface CompetitionCardProps {
    competition: Client<Event>;
}

const CompetitionCard: React.FunctionComponent<CompetitionCardProps> = ({ competition }) => (
    <DashboardCard
        header={competition.name}
        subHeader={getDateDescription(competition.date)}
        link={`/calendar?date=${formatISO(getDate(competition.date), { representation: "date" })}`}
    >
        <Icon name="flag" size="larger" styles={iconStyle} />
    </DashboardCard>
);

export { CompetitionCard };
