import { ToastOptions } from "./ToastContext";

export interface ToastInfo extends ToastOptions {
    id: number;
    message: string;
}

export type ToastAction =
    ({ type: "ADD_TOAST", id: number, message: string, options?: ToastOptions })
  | ({ type: "REMOVE_TOAST", id: number });

export const addToast = (id: number, message: string, options?: ToastOptions): ToastAction => ({ type: "ADD_TOAST", id, message, options });
export const removeToast = (id: number): ToastAction => ({ type: "REMOVE_TOAST", id });

export const toastReducer = (state: ToastInfo[], action: ToastAction): ToastInfo[] => {

    switch (action.type) {
        case "ADD_TOAST":
            const { id, message, options } = action;
            return [
                ...state,
                { id, message, ...options }
            ];
        case "REMOVE_TOAST":
            return [
                ...state.filter(s => s.id !== action.id)
            ];
        default:
            return state;
    }
};
