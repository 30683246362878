import { createContext } from "react";

import { User } from "../../server/auth/User";

export interface UserContextType {
    user?: User;
    loading: boolean;
}

export const UserContext = createContext<UserContextType>({ loading: false });
