import * as React from "react";
import { Box, ComponentSlotStyle, Flex, Header, HeaderDescription } from "@fluentui/react";
import { Link } from "react-router-dom";

import { cardStyle } from "../theming/cardStyle";

const dashboardCardStyle: ComponentSlotStyle = {
    ...cardStyle(),
    display: "block",
    textDecoration: "none"
};

export const dashboardCardHeaderStyle: ComponentSlotStyle = ({
    margin: "0.3em 0 0.3em 0.5em",
    fontWeight: 600,
    textDecoration: "none"
});

export const dashboardCardHeaderDescriptionStyle: ComponentSlotStyle = ({
    fontSize: "0.7em",
    textDecoration: "none"
});

interface DashboardCardProps {
    header: string;
    subHeader?: string;
    headerAs?: string;
    footer?: JSX.Element;
    link?: string;
}

const DashboardCard: React.FunctionComponent<DashboardCardProps> = ({ children, footer, header, subHeader, headerAs, link }) => (
    <Box styles={dashboardCardStyle} as={link && Link} to={link}>
        <Flex vAlign="center">
            <Flex.Item grow>
                <Header as={headerAs ?? "h2"} styles={dashboardCardHeaderStyle}>
                    {header}
                    {subHeader && (
                        <HeaderDescription as="span" styles={dashboardCardHeaderDescriptionStyle}>
                            {subHeader}
                        </HeaderDescription>
                    )}
                </Header>
            </Flex.Item>
            <Box styles={{ paddingRight: "0.5em" }}>
                {children}
            </Box>
        </Flex>
        {footer}
    </Box>
);

export { DashboardCard };
