import gql from "graphql-tag";
import { startOfDay, startOfWeek } from "date-fns";
import { useQuery } from "react-apollo";
import { useState } from "react";

import { Client } from "../../common";
import { WorkoutTotals } from "../../server/events/WorkoutTotals";
import { Event } from "../../server/events/Event";
import { Plan } from "../../server/plans/Plan";
import { WeeklyGoal } from "../../server/weeklyGoals/WeeklyGoal";

const TODAYS_WORKOUTS = gql`
    query todaysWorkout($date: DateTime) {
        events(
            filter: {
                date: {
                    EQ: $date
                }
                type: { EQ: Workout }
            },
            sort: { date: ASC }
        ) {
            _id
            name
            date
            duration
            complete
            skipped
        }
    }
`;

const LAST_WORKOUT = gql`
    query lastWorkout($date: DateTime) {
        events(
            filter: {
                date: {
                    LT: $date
                }
                type: { EQ: Workout }
            }
            sort: { date: DESC }
            pagination: { limit: 1 }
        ) {
            _id
            name
            date
            complete
            skipped
        }
    }
`;

const NEXT_COMPETITION = gql`
    query nextCompetition($date: DateTime) {
        events(
            filter: {
                date: {
                    GTE: $date
                }
                type: { EQ: Competition }
            }
            sort: { date: ASC }
            pagination: { limit: 1 }
        ) {
            _id
            name
            date
        }
    }
`;

const WORKOUT_TOTALS = gql`
    query workoutTotals {
        workoutTotals {
            planned
            complete
        }
    }
`;

const CURRENT_GOAL = gql`
    query currentGoal($date: DateTime) {
        weeklyGoals(
            filter: {
                date: { EQ: $date }
            }
        ) {
            _id
            hours
        }
    }
`;

const CURRENT_PLAN = gql`
    query currentPlan($date: DateTime) {
        plans(
            filter: {
                end: { GT: $date }
            }
            sort: { end: ASC }
            pagination: { limit: 1 }
        ) {
            _id
            name
            start
            end
        }
    }
`;

interface EventQueryResult {
    events: Client<Event>[];
}

interface WorkoutTotalsResult {
    workoutTotals: WorkoutTotals;
}

interface CurrentGoalResult {
    weeklyGoals: Client<WeeklyGoal>[];
}

export const useDashboardData = () => {

    const [today] = useState(startOfDay(new Date()));
    const queryOptions = { variables: { date: today }};

    const todaysWorkoutResult = useQuery<EventQueryResult>(TODAYS_WORKOUTS, queryOptions);
    const lastWorkoutResult = useQuery<EventQueryResult>(LAST_WORKOUT, queryOptions);
    const nextCompetitionResult = useQuery<EventQueryResult>(NEXT_COMPETITION, queryOptions);
    const currentPlanResult = useQuery<{ plans: Client<Plan>[] }>(CURRENT_PLAN, queryOptions);
    const workoutTotalsResult = useQuery<WorkoutTotalsResult>(WORKOUT_TOTALS);
    const currentGoalResult = useQuery<CurrentGoalResult>(CURRENT_GOAL, { variables: { date: startOfWeek(today, { weekStartsOn: 1 }) } });

    const loading = todaysWorkoutResult.loading
        || lastWorkoutResult.loading
        || nextCompetitionResult.loading
        || currentPlanResult.loading
        || workoutTotalsResult.loading
        || currentGoalResult.loading;

    const todaysWorkouts = todaysWorkoutResult.data?.events;
    const lastWorkout = lastWorkoutResult.data?.events[0];
    const nextCompetition = nextCompetitionResult.data?.events[0];
    const currentPlan = currentPlanResult.data?.plans[0];
    const workoutTotals = workoutTotalsResult.data?.workoutTotals;
    const currentGoal = currentGoalResult.data?.weeklyGoals[0];

    const refetchWorkouts = () => {
        todaysWorkoutResult.refetch();
        lastWorkoutResult.refetch();
        workoutTotalsResult.refetch();
    };

    return {
        loading,
        today,
        todaysWorkouts,
        lastWorkout,
        nextCompetition,
        currentPlan,
        workoutTotals,
        currentGoal,
        refetchWorkouts
    };
};
