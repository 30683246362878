import { ComponentSlotStyle } from "@fluentui/react";

export const cardStyle = (raised?: boolean): ComponentSlotStyle => ({
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: raised
        ? "rgba(34,36,38,0.3)"
        : "rgba(34,36,38,.15)",
    boxShadow: raised
        ? "0 2px 4px 0 rgb(35,36,38,0.12), 0 2px 10px 0 rgb(35,36,38,0.15)"
        : "0 1px 2px 0 rgb(35,36,38,0.12)",
    borderRadius: "3px",
});
