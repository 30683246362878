import * as React from "react";
import { render } from "react-dom";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "apollo-boost";
import { OperationDefinitionNode } from "graphql";
import { getMainDefinition } from "apollo-utilities";
import { ApolloProvider } from "react-apollo";
import { mergeThemes, Provider, themes } from "@fluentui/react";

import "./registerServiceWorker";
import { App } from "./app";
import { UserContextProvider } from "./user/UserContextProvider";
import { config } from "./config";
import { theme } from "./theming/theme";
import { ToastContainer } from "./toasts/ToastContainer";

const stripTypeNameFromMutations = new ApolloLink((operation, forward) => {
    const omitTypename = (key: any, value: any) =>
        key === "__typename" ? undefined : value;

    const def = getMainDefinition(operation.query);

    if (def && (def as OperationDefinitionNode).operation === "mutation") {
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
    }

    return forward ? forward(operation) : null;
});

const client = new ApolloClient({
    link: ApolloLink.from([
        stripTypeNameFromMutations,
        new HttpLink({
            uri: `${config.serverUrl}/graphql`,
            credentials: "include"
        })
    ]),
    cache: new InMemoryCache()
});

render(
    <Provider theme={mergeThemes(themes.teams, theme)}>
        <ApolloProvider client={client}>
            <ToastContainer>
                <UserContextProvider>
                    <App />
                </UserContextProvider>
            </ToastContainer>
        </ApolloProvider>
    </Provider>,
    document.getElementById("app-root")
);
