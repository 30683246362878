import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";

import { User } from "../../server/auth/User";

import { UserContext } from "./UserContext";

const GET_USER = gql`
    query user {
        currentUser {
            _id
            displayName
            email
            googleId
        }
    }
`;

const UserContextProvider: React.FunctionComponent = ({ children }) => {

    const { data, loading } = useQuery<{ currentUser: User }>(GET_USER);
    const context = { user: data?.currentUser, loading };

    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContextProvider };
