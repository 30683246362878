import { useContext } from "react";

import { ToastContext, ToastFunction } from "./ToastContext";

export const useToast = () => {
    const { toast } = useContext(ToastContext);

    const success: ToastFunction = (message, options) => toast(message, { ...options, type: "success" });
    const warning: ToastFunction = (message, options) => toast(message, { ...options, type: "warning" });
    const error: ToastFunction = (message, options) => toast(message, { ...options, type: "error" });

    return Object.assign(
        {},
        toast,
        {
            success,
            warning,
            error
        }
    );
};
