import { format, isToday, isTomorrow, isYesterday } from "date-fns";
import { enGB } from "date-fns/locale";

import { getDate } from "../../common";

export const getDateDescription = (value: string | Date | undefined) => {

    if (value === undefined) {
        return "";
    }

    const date = getDate(value);

    if (isToday(date)) {
        return "Today";
    }

    if (isTomorrow(date)) {
        return "Tomorrow";
    }

    if (isYesterday(date)) {
        return "Yesterday";
    }

    return format(date, "EEE PPP", { locale: enGB });
};
