import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Box, Flex, FlexItem, Loader } from "@fluentui/react";

import { Dashboard } from "./dashboard/Dashboard";
import { MenuBar } from "./layout/MenuBar";
import { Unauthenticated } from "./user/Unauthenticated";
import { useCurrentUser } from "./user/useCurrentUser";
import { ErrorBoundary } from "./ErrorBoundary";

export const App: React.FC = () => {

    const [user, loading] = useCurrentUser();

    const PlanOverview = React.lazy(() => import(/* webpackChunkName: "plans" */ "./plans"));
    const Calendar = React.lazy(() => import(/* webpackChunkName: "calendar" */ "./calendar"));

    return (
        <section>
            <Router>
                <Flex column styles={{ height: "100%"}}>
                    <FlexItem shrink={false}>
                        <MenuBar />
                    </FlexItem>
                    <FlexItem grow styles={{ position: "relative", overflow: "scroll" }}>
                        <div>
                            <Box styles={{ position: "absolute", height: "100%", width: "100%" }}>
                                <ErrorBoundary>
                                    <React.Suspense fallback={<Loader size="larger" delay={50} />}>
                                        {loading && user == null
                                            ? <Loader size="larger" />
                                            : user != null
                                                ? (
                                                    <>
                                                        <Route exact path="/" component={Dashboard} />
                                                        <Route path="/plan" component={PlanOverview} />
                                                        <Route path="/calendar" component={Calendar} />
                                                    </>
                                                )
                                                : <Unauthenticated />
                                        }
                                    </React.Suspense>
                                </ErrorBoundary>
                            </Box>
                        </div>
                    </FlexItem>
                </Flex>
            </Router>
        </section>
    );
};
