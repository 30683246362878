import { format, isAfter, isBefore, isEqual } from "date-fns";

type DateType = string | Date | D8;

export const isD8 = (value: DateType): value is D8 => (value as D8).value !== undefined;
const getValue = (date: DateType) => isD8(date) ? date.value : new D8(date).value;

export class D8 {

    public readonly value: Date;

    constructor(value?: string | Date) {

        if (value === undefined) {

            this.value = new Date();

        } else if (typeof value === "string") {

            this.value = new Date(value);

        } else {

            this.value = value;
        }
    }

    public format = (
        formatString: string,
        options?: {
          locale?: Locale
          weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
          firstWeekContainsDate?: number
          useAdditionalWeekYearTokens?: boolean
          useAdditionalDayOfYearTokens?: boolean
        }
    ): string => format(this.value, formatString, options)

    public isAfter = (date: DateType) => isAfter(this.value, getValue(date));

    public isAfterOrEqual = (date: DateType) => isAfter(this.value, getValue(date)) || isEqual(this.value, getValue(date));

    public isBefore = (date: DateType) => isBefore(this.value, getValue(date));

    public isBeforeOrEqual = (date: DateType) => isBefore(this.value, getValue(date)) || isEqual(this.value, getValue(date));

    public isEqual = (date: DateType) => isEqual(this.value, getValue(date));

    public isBetween = (start: DateType, end: DateType) => this.isBeforeOrEqual(end) && this.isAfterOrEqual(start);
}
