import * as React from "react";
import { Box, ComponentSlotStyle } from "@fluentui/react";

const containerStyle = {
    "maxWidth": "72em",
    "margin": "0 auto",
    "@media screen and (max-width: 70em)": {
        marginLeft: "1em",
        marginRight: "1em"
    }
};

interface ContainerProps {
    styles?: ComponentSlotStyle;
}

export const Container: React.FunctionComponent<ContainerProps> = ({ children, styles }) => (
    <Box
        styles={{
            ...containerStyle,
            ...styles
        }}
    >
        {children}
    </Box>
);
