import * as React from "react";
import gql from "graphql-tag";
import { useMutation } from "react-apollo";
import { ComponentEventHandler, Dialog, Form, Input, InputProps } from "@fluentui/react";

import { Client } from "../../common";
import { Event } from "../../server/events/Event";
import { useDialog } from "../useDialog";
import { useToast } from "../toasts";

const UPDATE_EVENT = gql`
    mutation updateEvent(
        $id: ID!,
        $event: EventInput!
    ) {
        updateEvent(
            id: $id,
            update: $event
        ) {
            _id
        }
    }
`;

interface UpdateEventVariables {
    id: string;
    event: Event;
}

export const useEditEvent = (event?: Client<Event>, onSaved?: () => any) => {

    const [state, setState] = React.useState<Partial<Client<Event>>>(event ?? {});
    const { isOpen, open, close } = useDialog();
    const toast = useToast();

    const [updateEvent] = useMutation<UpdateEventVariables>(
        UPDATE_EVENT,
        {
            onCompleted: () => onSaved?.()
        }
    );

    const submit = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { _id, ...update } = state;
        await updateEvent({ variables: { id: _id, event: update }});
        toast.success("Event updated");
    };

    const complete = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { _id, ...update } = state;
        updateEvent({ variables: { id: _id, event: { ...update, complete: true }}});
        toast.success("Workout Completed!");
    };

    const skipWorkout = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { _id, ...update } = state;
        updateEvent({ variables: { id: _id, event: { ...update, skipped: true }}});
        toast.warning("Workout skipped");
        close();
    };

    const onReasonChange: ComponentEventHandler<InputProps & { value: string }> = (_, data) => setState({ ...state, skippedReason: data?.value });

    const skipDialog = (
        <Dialog
            header="Are you sure want to skip this workout?"
            cancelButton="No"
            confirmButton="Yes"
            content={
                <Form.Field
                    label="Reason"
                    name="reason"
                    control={<Input onChange={onReasonChange} />}
                />
            }
            open={isOpen}
            onConfirm={skipWorkout}
            onCancel={close}
        />
    );

    return {
        state,
        setState,
        complete,
        submit,
        skipDialog,
        skip: open
    };
};
